<template>
    <div class="weight-calculator">
        <label for="weight" class="form-label --centred">What does your letter, packet or parcel weigh? Max 30kg</label>
        <div class="weight-calculator.fieldset-container">
            <div class="weight-calculator.input-container" :class="{ '--active': !gramsIsActive }">
                <input 
                    type="number" 
                    min="0" 
                    :max="computedMax" 
                    :step="computedStep" 
                    name="weight" 
                    id="weight" 
                    class="form-number-field weight-calculator.input" 
                    v-model="computedWeight"
                    @change="handleChange($event)"
                    placeholder="0">
            </div>
            <div class="weight-calculator.weight-selection">
                <span class="weight-calculator.weight-selection.grams" @click="setGramsActive(true)" :class="{ '--active': gramsIsActive }">
                    <span class="weight-calculator.weight-selection.grams.text">Grams</span>
                </span>
                <span class="weight-calculator.weight-selection.kilos" @click="setGramsActive(false)" :class="{ '--active': !gramsIsActive }">
                    <span class="weight-calculator.weight-selection.kilos.text">Kilos</span>
                </span>
            </div>
        </div>
        <p class="error" v-if="mainstore.selected.weight !== null && (mainstore.selected.weight < 1 || mainstore.selected.weight === 0 || mainstore.selected.weight === '' || mainstore.selected.weight > maxGrams)">{{ validationText }}</p>
    </div>
</template>

<script>
import { useMainStore } from '@/stores/MainStore.ts';

export default {
    setup() {
        const mainstore = useMainStore();
        return {
            mainstore,
        };
    },

    name: 'WeightCalculator',

    data() {
        return {
            gramsIsActive: true,
            stepGrams: 5,
            stepKilos: 0.25,
            maxGrams: 30000,
            maxKilos: 30,
            validationText: 'You must enter a valid weight',
        };
    },

    computed: {
        computedWeight: {
            get() {
                if (this.gramsIsActive) {
                    return this.mainstore.selected.weight;
                }
                return this.mainstore.selected.weight / 1000;
            },
            set(val) {
                if (this.gramsIsActive) {
                    this.mainstore.selected.weight = val;
                    return;
                }
                this.mainstore.selected.weight = val * 1000;
            }
        },
        computedStep() {
            return this.gramsIsActive ? this.stepGrams : this.stepKilos;
        },
        computedMax() {
            return this.gramsIsActive ? this.maxGrams : this.maxKilos;
        }
    },

    methods: {
        setGramsActive(isGramsActive) {
            this.gramsIsActive = isGramsActive;
        },

        setWeight() {
            this.weightInGrams = this.computedWeight;
        },

        validateWeight(event) {
            let value = event.target.value;

            if (this.gramsIsActive) {
                // For grams, round the value to the nearest whole number
                value = Math.round(value);
            } else {
                // For kilograms, round the value to three decimal places
                value = parseFloat(value).toFixed(3);
            }

            if (value < 0) {
                value = 0;
            }

            event.target.value = value;
            this.computedWeight = value ? parseFloat(value) : 0;
        },

        handleChange($event) {
            this.setWeight();
            this.validateWeight($event);
            this.mainstore.selected.parcelSize = [];
            this.mainstore.selected.service = null;
            this.mainstore.selected.serviceDetails = [];
        }
    }
};
</script>
